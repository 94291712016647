import React from 'react';
import styles from "./Awards.module.scss"
import Container from "../../UI/Container";

const Awards = () => {
    return (
        <div className={styles.body} id={"awards"}>
            <Container>
                <div className={styles.bodyWrapper}>


                    <div className={styles.wrapper}>
                        <div className={styles.text}>
                            <div className={styles.title}>
                            Awards
                            </div>
                                <div className={styles.description}>
                                At the festival, outstanding creative minds will be awarded unique samurai-shaped statuettes, embodying not only beauty but also the strength and artistry of cinema.                                 </div>
                                <div className={styles.description}>
                                Distinguished works will become the proud holders of these elegant symbols.                                 </div>
                                <div className={styles.description}>
                                Award winners will also have the opportunity to present their works at our festival, providing audiences with the chance to immerse themselves in the world of cinema through the lenses of talented directors.                                 </div>
                        </div>
                    </div>
                    <div className={styles.images}>
                        <img src={"/images/Awards/prize.png"}/>
                        <div className={styles.img_text}>
                        *The award may differ from the one presented on the website
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Awards;