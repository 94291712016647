import React, {useState} from 'react';
import styles from "./Rules.module.scss"
import { Button, Modal } from 'antd';
import Container from "../../UI/Container";

const Rules = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    return (
        <div className={styles.wrapper} id={"rules"}>
            <Container>
            <div className={styles.inner}>
                <div className={styles.title}>              
                Official Submission Guidelines
                </div>
                <div className={styles.description}>
                The festival has several recommendations that should be followed in order for your film to successfully pass the selection                </div>
            
            <div className={styles.buttonBlock}>
                    <Button className={styles.button} type="primary" onClick={()=>setIsModalOpen(!isModalOpen)}>
                    Show Submission
                    </Button>
                </div>
                <Modal
                    onCancel={()=>setIsModalOpen(!isModalOpen)}
                    footer={[
                        <Button className={styles.okButton} key="ok" onClick={()=>setIsModalOpen(!isModalOpen)}>
                            Ok
                        </Button>,
                    ]}
                    title="More"
                    open={isModalOpen}
                >
                    <p>
                    Article 1: Genre Eligibility
                    1.1 The festival accepts film submissions in various genres including, but not limited to, drama, comedy, documentary, and experimental projects.
                    </p>
                    <p>

Article 2: Language and Subtitling Requirements
2.1 Submissions may be in any language. If the original language is not Japanese, the provision of English subtitles is mandatory.  
                    </p>
                    <p>Article 3: Format of Submissions
3.1 The festival accepts submissions in both short and feature-length formats.
3.2 Short films are defined as having a duration of up to 50 minutes.
3.3 Feature-length films are those exceeding 50 minutes in duration.
3.4 All submissions must be in digital format.
                    </p>
                    <p>rticle 4: Submission Deadlines and Announcement of Results
4.1 The closing date for submissions is updated regularly on the official FilmFreeway page.
4.2 The announcement of results will also be available on the official FilmFreeway page.
                    </p>
                    <p>Article 5: Modification of Festival Dates
5.1 The festival organizers reserve the right to alter the festival dates at their discretion without prior notice.
5.2 All participants will be informed in the event of any changes to the schedule.
                    </p>
                    <p>
                    Article 6: Organizational Discretion in Selection and Rejection of Submissions
6.1 The selection of winning submissions is at the sole discretion of the festival organizers.
6.2 The organizers reserve the right to reject any submission without obligation to provide justification, particularly in cases where submissions do not comply with the festival's rules and standards.
                    </p>
                    <p>Article 7: Eligibility Criteria
7.1 All filmmakers, regardless of nationality or experience level, are eligible to submit their films.
7.2 Employees and family members of the festival organizers are not eligible to participate.
                    </p>
                    <p>
Article 8: Submission Fees
8.1 Details regarding submission fees, if any, are available on the official FilmFreeway page.
8.2 Fees are non-refundable, except in circumstances deemed exceptional by the festival organizers.
                    </p>
                    <p>
Article 9: Technical Requirements and Submission Process
9.1 Initial submissions must be made in digital format via the FilmFreeway platform.
9.2 After the initial selection process, the festival organizers will contact the filmmakers to request a DCP (Digital Cinema Package) version of the selected films for final screening.
                    </p>
                    <p>Article 10: Intellectual Property and Rights
10.1 By submitting, filmmakers confirm they hold all necessary rights and permissions for both the film and its content, including music and other intellectual property.
10.2 The festival reserves the right to use posters and publicly available marketing material associated with the submitted films for promotional purposes without prior consent from the filmmaker.
10.3 The use of film excerpts, including trailers, for promotional purposes is subject to prior approval and agreement with the filmmaker.</p>
                    <p>Article 11: Privacy and Data Use
11.1 Personal data collected during the submission process, including emails, will be used solely for the purpose of the festival. This includes communications regarding the festival and email newsletters from the festival and its partners.
11.2 Participants will have the option to opt out of these email communications at any time by unsubscribing via a link provided in the newsletter.
                    </p>
                    <p>Article 12: Liability and Indemnity
12.1 The festival is not responsible for any lost, damaged, or late submissions.
12.2 Filmmakers agree to indemnify and hold harmless the festival from any claims arising from their submission.
                    </p>
                    <p>Article 13: Compliance and Disqualification
13.1 Non-compliance with any of the festival’s rules may result in disqualification.
13.2 The festival organizers reserve the right to disqualify any submission that is deemed inappropriate or offensive.          </p>
                    <p>Article 14: Amendments to Guidelines
14.1 The festival organizers reserve the right to amend these guidelines as necessary. Any changes will be communicated through the official FilmFreeway page.
                    </p>
                </Modal>
                </div>
            </Container>
            
        </div>
    );
};

export default Rules;
