import React from 'react';
import styles from "./Second_About.module.scss"
import Container from "../../UI/Container";

const Second_About = () => {
    return (
        <div className={styles.body} id={"aboutUS"}>
            <Container>
                <div className={styles.bodyWrapper}>

                <div className={styles.images}>
                <a href={'https://filmfreeway.com/KawaramachiFilmFestival'} className={styles.button}>
                            <img src={"/images/FFButtons/pink.png"}/>
                        </a>
                    </div>

                    <div className={styles.wrapper}>
                        <div className={styles.text}>
                                <div className={styles.description}>
                                Our selection process for the Kawaramachi Kyoto Film Festival is meticulously curated, beginning with an initial review of digital submissions through FilmFreeway.                                 </div>
                                <div className={styles.description}>
                                Films that pass this stage are then featured in a series of screenings, providing filmmakers a distinguished platform to showcase their work to a discerning audience.                                </div>
                                <div className={styles.description}>
                                This year's festival is scheduled for September, set in the culturally city of Kyoto.                                </div>
                        </div>
                    </div>

                </div>
            </Container>
        </div>
    );
};

export default Second_About;