
import Header from "./components/blocks/Header";
import Banner from "./components/blocks/Banner";
import About from "./components/blocks/About";
import Second_About from "./components/blocks/Second_About";
import Footer from "./components/blocks/Footer";
import Aiming from "./components/blocks/Aiming";
import Rules from "./components/blocks/Rules";
import Awards from "./components/blocks/Awards";

function App() {
  return (
    <div className='App'>
      <Header/>
      <Banner/>
      <About/>
      <Second_About/>
      <Awards/>
      <Rules/>
      <Aiming/>
      <Footer/>
    </div>

  );
}

export default App;
