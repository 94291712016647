import React, {useEffect, useState} from 'react';
import styles from "./Header.module.scss"
import {Dropdown} from "antd";
import Container from "../../UI/Container";
import {UnorderedListOutlined} from "@ant-design/icons";

const items = [
    {
        key: '1',
        label: (
            <a href={"#about"} className={styles.burgerItem}>ABOUT</a>
        ),
    },
    {
        key: '2',
        label: (
            <a href={"#rules"} className={styles.burgerItem}>GUIDELINES</a>
        ),
    },
    {
        key: '3',
        label: (
            <a href={"#awards"} className={styles.burgerItem}>AWARDS</a>
        ),
    },
    {
        key: '4',
        label: (
            <a href={"https://filmfreeway.com/Levelupsydney"} className={styles.burgerItem}>SUBMIT NOW</a>
        ),
    },
];

const Index = () => {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (

        <div className={`${styles.headerWrapper} ${scrolled ? styles.scrolled : ""}`} >
            <Container>
            <div className={styles.inner}>
                <a href={"/"} className={styles.logo}>
                    <img src={"/images/Header/logo.png"}/>
                </a>
                <div className={styles.headerItems}>
                    <a href={"#about"} className={styles.headerItem}>ABOUT</a>
                    <a href={"#rules"} className={styles.headerItem}>GUIDELINES</a>
                    <a href={"#awards"} className={styles.headerItem}>AWARDS</a>
                    <a href={" https://filmfreeway.com/KawaramachiFilmFestival"} className={styles.headerItem}>SUBMIT</a>
                </div>
                <div className={styles.burger}>
                    <Dropdown
                        menu={{
                            items,
                        }}
                        placement="bottomRight"
                    >
                        <UnorderedListOutlined style={{color: "#FFFFFF"}} />
                    </Dropdown>
                </div>
            </div>
            </Container>


        </div>
    );
};

export default Index;